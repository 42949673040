












































import Vue from "vue";


export default Vue.extend ({

    computed:{
        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("tradeshow") || {};
        },

        sponsorsData(): Record<string, any> {
            return this.pageConfig.staticSponsorData;
        }
    },
    methods: {

        loadImage(img: string) {
            return `/logos/${img}`;
        }
    }
})
